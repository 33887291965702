import React from "react"
import { Helmet } from "react-helmet"
import Layout from "../../components/layout"
import Seo from "../../components/seo"

const RoofingFinancingPage = () => {
  return (
    <Layout>
      <Seo
        title={"Financing For Roofing Contractors"}
        description={
          "Become more competitive, boost your close rate, and grow your project size with Enerbank&#39;s financing options for roofing contractors"
        }
      />
      <Helmet>
        <body className="page-roofing-financing industry" />
        <script type="application/ld+json" async={true}>
          {`{
 "@context": "https://schema.org/",
 "@type": "Product",
 "name": "Financing For Roofing Contractors",
 "image": "",
 "description": "Our loan products are designed specifically for home renovation, including the roofing industry"
 }`}
        </script>
      </Helmet>

      <section
        className="banner-area editable-page-hero-banner"
        id="roofing-page-banner-section"
        style={{
          backgroundImage:
            "url(" +
            "http://gbdevpress.enerbank.com/wp-content/uploads/2022/04/roofing-hero-image.jpg" +
            ")",
        }}
      >
        <div className="row align-items-center relative banner-area-text-container">
          <div className="container text-center">
            <h1 className="text-white" style={{ padding: "80 0" }}>
              Financing For Roofing Contractors
            </h1>
          </div>
        </div>
      </section>
      <section className="paragraph-text-block">
        <div className="container">
          <div className="justify-content-center">
            <p>
              Have you been losing out on jobs to your competition? You&#39;re
              not alone. Many roofing contractors find themselves missing out on
              customers because they aren&#39;t offering payment options as a
              part of their business.
            </p>
          </div>
        </div>
      </section>
      <div class="benefits-head">
        <h2 className="text-center bg-blue">
          Why Should Roof Contractors Offer Financing?
        </h2>
      </div>
      <section className="financing-benefits">
        <div className="container">
          <div className="row">
            <div className="col-md-4">
              <div class="card">
                <div class="card-image">
                  <img
                    src="http://gbdevpress.enerbank.com/wp-content/uploads/2022/04/more-competitive.png"
                    class=""
                    alt="finishing blocks icon"
                  />
                </div>
                <div class="card-body">
                  <h3>Become More Competitive</h3>
                  <p class="card-text">
                    Most customers like to shop around for the best deal when it
                    comes to funding a roofing project. When you offer flexible,
                    affordable payment options, you&#39;ll become a more
                    enticing option and win over new customers.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div class="card">
                <div class="card-image">
                  <img
                    src="http://gbdevpress.enerbank.com/wp-content/uploads/2022/05/boost-closing-rate.png"
                    class=""
                    alt="increased percentage icon"
                  />
                </div>
                <div class="card-body">
                  <h3>Boost Close Rate</h3>
                  <p class="">
                    Same-As-Cash loans make it easy for homeowners to make a
                    decision&nbsp;—&nbsp;meaning you&#39;ll close more deals
                    quickly. In fact, when offering a choice of Same-As-Cash
                    loan or low monthly payment loan, our contractors can see
                    their close rate nearly double<sup>*</sup> (assuming a base
                    close rate of 25%). <br />
                    <small>
                      *Based on the Brickyard Study commissioned by EnerBank
                      USA. 2018.
                    </small>
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div class="card">
                <div class="card-image">
                  <img
                    src="http://gbdevpress.enerbank.com/wp-content/uploads/2022/05/grow-average-project.png"
                    class=""
                    alt="more money icon"
                  />
                </div>
                <div class="card-body">
                  <h3>Grow Average Project Size</h3>
                  <p class="">
                    Research shows that one-third of homeowners regret not
                    spending more on their project, and when offered payment
                    options, they spend an average of 43%<sup>*</sup> more on
                    their renovation. This higher spending will boost your
                    average project size and help you increase revenue over time
                    by offering higher-quality roofing materials or other
                    add-ons to the project, such as new gutters.
                    <br />
                    <small>
                      *Based on the Brickyard Study commissioned by EnerBank
                      USA. 2018.
                    </small>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        className="container-fluid right-image-left-text relative offer-financing"
        id="roofing-financing-left-text-right-image-section"
        style={{ backgroundColor: "#0077c8" }}
      >
        <div className="row">
          <div className="col-md-l2 col-lg-12 col-xl-12 col-md-12 module-content">
            <div className="row">
              <div className="col-md-6 text-side">
                <div className="container">
                  <div className="row">
                    <div className="column-text text-white">
                      <h2 className="text-white">
                        How do Roofing Contractors Offer Financing?
                      </h2>
                      <p>
                        We&#39;ve made it simple for homeowners to apply with
                        three paperless application methods, including online,
                        in the app, or over the phone. With quick approvals, you
                        can get everything signed, sealed, and delivered before
                        you leave the home. Once the project begins, requesting
                        funds from homeowners is just a click away. They&#39;ll
                        receive the request via their preferred contact method
                        and typically the funds are deposited within 24 hours of
                        the homeowner&#39;s approval.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="col-md-6 image-side"
                style={{
                  backgroundImage:
                    "url(" +
                    "http://gbdevpress.enerbank.com/wp-content/uploads/2023/12/service-stability-convenience.jpg" +
                    ")",
                }}
              ></div>
            </div>
          </div>
        </div>
      </section>
      <section className="start-financing">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h2 className="text-center">
                Upgrade Your Roof Financing With EnerBank!
              </h2>
              <p>
                If you&#39;re already offering payment options, but aren&#39;t
                happy with your current provider, let us share a little about
                what makes EnerBank different. First, we are experts in the home
                improvement industry. That means our loan products are designed
                specifically for home renovation, including the roofing
                industry. Second, we aren&#39;t here just to provide financing.
                When you work with us, you&#39;ll have access to your own
                dedicated relationship manager, high-quality marketing
                materials, and a US-based customer service team, all for no
                additional costs. Finally, we treat your customers like our
                customers, providing a fast, easy, and convenient way for
                homeowners to get the project they want when they want
                it&nbsp;—&nbsp;making for an excellent customer experience that
                can lead to referrals.
              </p>
              <p className="text-center">
                <a
                  className="btn btn-primary px-5"
                  style={{
                    marginTop: "2rem",
                    padding: "0.75rem",
                  }}
                  href="/sign-up/"
                >
                  Get Started Today!
                </a>
              </p>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default RoofingFinancingPage
